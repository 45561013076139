import essa from "../../assets/img/essa-img.jpg"
import peh from "../../assets/img/peh-img.jpg"
import liad from "../../assets/img/liad-img.jpg"
import campus from "../../assets/img/campus-france.jpg"
import bourses from "../../assets/img/bourses-img.jpg"
import alumni from "../../assets/img/alumni-img.jpg"
import cned from "../../assets/img/cned.png"

const  etudes = [
    {
        region:"france",
        url : "etudes/en-france",
        description:`
          `,
        etudes:[
            {
                title:"Campus France",
                url:"campus-france",
                img:campus
            },
            {
                title:"CNED",
                url:"cned",
                img:cned
            },
            {
                title:"France Alumni",
                url:"france-alumni",
                img:alumni
            },
            {
                title:"Bourses",
                url:"bourses",
                img:bourses
            }
        ]

    },
    {
        region:"algérie",
        url : "etudes/en-algerie",
        description:`
          Algerie Id officia inventore et voluptas enim est
          voluptas aliquid. Qui accusamus autem est totam aperiam aut officia
          rerum nam incidunt veritatis est molestiae nesciunt. Ut fugit velit
          hic accusantium earum est natus doloremque qui molestias modi nam
          consequuntur dicta ab numquam est molestiae nesciunt. Ut fugit velit
          hic accusantium earum est natus doloremque qui molestias modi nam
          consequuntur dicta ab numquam.`,
        etudes:[
            {
                title:"LIAD",
                url:"liad",
                img:liad
            },
            {
                title:"PEH",
                url:"peh",
                img:peh
            },
            {
                title:"Programmes universitaires",
                url:"essa",
                img:essa
            }
        ]
        
    }
]

const  etudesHome = [
    {
        region:"france",
        url : "etudes/en-france",
        description:`<p>
        La coopération algéro-française en matière éducative et universitaire est riche et multiple et se déploie aussi bien en Algérie qu’en France.
        <br/>
        En Algérie, cette coopération se traduit par la présence d’un réseau éducatif et universitaire de grande qualité avec deux établissements scolaires français, homologués par le Ministère de l’Education nationale et de la jeunesse français, le Lycée International Alexandre Dumas (Alger, Annaba et Oran) et la Petite Ecole d’Hydra (Alger), et un établissement universitaire algéro-français l’Ecole Supérieure des Affaires Algérienne (ESAA).
        <br/>
        Les Etudiants algériens peuvent également étudier en France et sont accompagnés par le réseau Campus France de l’Institut français d’Algérie. Des bourses d’études en France sont aussi proposées aux étudiants et aux chercheurs algériens.</p>`,
        etudes:[
            {
                title:"Campus France",
                url:"en-france/campus-france",
                img:campus
            },
            {
                title:"CNED",
                url:"en-france/cned",
                img:cned
            },
            {
                title:"France Alumni",
                url:"en-france/france-alumni",
                img:alumni
            },
            {
                title:"Bourses",
                url:"en-france/bourses",
                img:bourses
            }
        ]

    },
    {
        region:"algérie",
        url : "etudes/en-algerie",
        description:`
          Algerie Id officia inventore et voluptas enim est
          voluptas aliquid. Qui accusamus autem est totam aperiam aut officia
          rerum nam incidunt veritatis est molestiae nesciunt. Ut fugit velit
          hic accusantium earum est natus doloremque qui molestias modi nam
          consequuntur dicta ab numquam est molestiae nesciunt. Ut fugit velit
          hic accusantium earum est natus doloremque qui molestias modi nam
          consequuntur dicta ab numquam.`,
        etudes:[
            {
                title:"LIAD",
                url:"en-algerie/liad",
                img:liad
            },
            {
                title:"PEH",
                url:"en-algerie/peh",
                img:peh
            },
            {
                title:"Programmes universitaires",
                url:"en-algerie/essa",
                img:essa
            }
        ]
        
    }
]

export {etudes,etudesHome}