
const info =[
    {
        id:13,
        name:"tlemcen",
        address:" 45, boulevard Dergal Abdelkader (Imam Ali), Résidence Yasmine Imama",
        phone:"+213 (0) 43 26 42 84",
        lat:34.883850,
        long:-1.314020,
        image:"https://images.unsplash.com/photo-1568792923760-d70635a89fdc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
        id:16,
        name:"alger",
        address:"1 rue de Tripoli Hussein Dey (station de métro/Tramway Ruisseau)",
        phone:"+213 (0)21 73 85 88",
        lat:36.771721,
        long:3.051760,
        image:"https://images.unsplash.com/photo-1527891751199-7225231a68dd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
        id:25,
        name:"constantine",
        address:"1 boulevard de l'indépendance",
        phone:"+213 (0) 31 91 25 91",
        lat:36.351830,
        long:6.611630,
        image:"https://images.unsplash.com/photo-1621640786029-220e9ff8dd09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80"
    },
    {
        id:23,
        name:"annaba",
        address:"8 Boulevard du 1er novembre 1954",
        phone:"+213 (0) 38 80 22 59",
        lat:36.926456,
        long:7.752535,
        image:"https://images.unsplash.com/photo-1583373834259-46cc92173cb7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    },
    {
        id:31,
        name:"oran",
        address:"112 rue Larbi Ben M’Hidi",
        phone:"+213 (0) 41 41 44 17",
        lat:35.697071,
        long:-0.630799,
        image:"https://images.unsplash.com/photo-1622397333309-3056849bc70b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    }
]

export default info