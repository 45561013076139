import essa from "../../assets/img/essa-img.jpg"
import peh from "../../assets/img/peh-img.jpg"
import liad from "../../assets/img/liad-img.jpg"
import campus from "../../assets/img/campus-france.jpg"
import bourses from "../../assets/img/bourses-img.jpg"
import alumni from "../../assets/img/alumni-img.jpg"
import cned from "../../assets/img/cned.png"
import { useTranslation } from 'react-i18next';


 function menuItems(t) { 
  return [
    {
        title:t("L'Institut"),
        url:"institut",
        submenu:[
          {
            title:t("Présentation"),
            url:"institut/presentation"
          },
          {
            title:t("Nos missions"),
            url:"institut/nos-missions",
          },
          {
            title:t("Notre équipe"),
            url:"institut/equipe"
          },
          {
            title:t("Recrutement"),
            url:"institut/recrutement "
          },
          {
            title:t("Nos espaces"),
            url:"institut/nos-espaces"
          }
            
            
        ]
    },
    {
        title:t("tests et examens"),
        url:"tests-et-examens",
        submenu:[
          {
            title:t("Présentation"),
            url:"tests-et-examens/presentation"
          },
          {
            title:t("TCF"),
            url:"tests-et-examens/tcf"
          },
          {
            title:t("DELF & DALF"),
            url:"tests-et-examens/delf-et-dalf"
          }
          
        ]
    },
    {
      title:t("cours de français"),
      url:"cours-de-francais",
      // submenu:[
      //   // {
      //   //   title:"Présentation",
      //   //   url:"cours-de-francais/presentation"
      //   // },
      //   // {
      //   //   title:"Inscription",
      //   //   url:"cours-de-francais/inscription"
      //   // },
      //   // {
      //   //   title:"Calendrier des Sessions",
      //   //   url:"cours-de-francais/calendrier-des-sessions"
      //   // }
      // ]
    },
    {
        title:t("études"),
        url:"etudes",
        submenu:[
            {
                title: t('France'),
                url:"etudes/en-france",
                submenu: [
                    {
                        title:t("Campus France"),
                        url:"etudes/en-france/campus-france",
                        img:campus
                    },
                    {
                      title:t("CNED"),
                      url:"etudes/en-france/cned",
                      img:cned
                  },
                    {
                        title:t("France Alumni"),
                        url:"etudes/en-france/france-alumni",
                        img:alumni
                    },
                    {
                        title:t("Bourses"),
                        url:"etudes/en-france/bourses",
                        img:bourses
                    }
                  
                ],
            },
            {
                title: t('Algérie'),
                url:"etudes/en-algerie",
                submenu:[
                    {
                        title:t("LIAD"),
                        url:"etudes/en-algerie/liad",
                        img:liad
                    },
                    {
                        title:t("PEH"),
                        url:"etudes/en-algerie/peh",
                        img:peh
                    },
                    {
                        title:t("Programmes universitaires"),
                        url:"etudes/en-algerie/essa",
                        img:essa
                    }
                ]
            },
            
        ]
    },
    {
        title:t("culture"),
        url:"culture",
        submenu:[
          {
            title: t('Nos actions culturelles'),
            url:"culture/nos-actions-culturelles"
        },
            {
                title: t('Agenda'),
                url:"culture/agenda",
                submenu: [
                    {
                        title:t("Musique"),
                        url:"culture/agenda/musique"
                    },
                    {
                        title:t("Théâtre"),
                        url:"culture/agenda/theatre"
                    },
                    {
                        title:t("Danse"),
                        url:"culture/agenda/danse"
                    },
                    {
                        title:t("Expo"),
                        url:"culture/agenda/expo"
                    },
                    {
                      title:t("Cinéma"),
                      url:"culture/agenda/cinema"
                    },
                    {
                      title:t("Conférences et rencontres"),
                      url:"culture/agenda/conferences-et-rencontres"
                    },
                    {
                        title:t("Jeunesse"),
                        url:"culture/agenda/jeunesse"
                    },
                    {
                      title:t("Ateliers"),
                      url:"culture/agenda/ateliers"
                    },
                ],
            },
            
        ]
    },
    {
      title:t("Médiathèques"),
      url:"mediatheque",
      submenu:[
        {
          title:t("Présentation"),
          url:"mediatheque/presentation"
        },
        {
          title:t("Inscription"),
          url:"mediatheque/inscription"
        },
        
        {
          title:t("Culturethèque"),
          url:"mediatheque/culturetheque"
        },
        
      ],
      submenuNational:
        {
          title:t("Médiathèques"),
          url:"mediatheque"
        }
      
    },
  ]}
  
const mobileMenuItems =(t)=> [
  {
    title: t("L'Institut"),
    submenu: [
      {
        title: "Présentation",
        url: "institut/presentation",
      },
      {
        title: "Nos missions",
        url: "institut/nos-missions",
        hide: ["alger", "oran", "constantine", "tlemcen", "annaba"],
      },
      {
        title: "Notre équipe",
        url: "institut/equipe",
      },
      {
        title:"Recrutement ",
        url:"institut/recrutement "
      },
      {
        title:"Nos espaces",
        url:"institut/nos-espaces"
      }
    ],
  },
  {
    title: "tests et examnes",
    submenu: [
      {
        title: "Présentation",
        url: "tests-et-examens/presentation",
      },
      {
        title: "TCF",
        url: "tests-et-examens/tcf",
      },
      {
        title: "DELF & DALF",
        url: "tests-et-examens/delf-et-dalf",
      },
    ],
  },
  {
    title: "cours de français",
    url: "cours-de-francais",
  },
  {
    title: "études",
    submenu: {
      "France": [
        {
          title: "Campus France",
          url: "etudes/en-france/campus-france",
          img: campus,
        },
        {
          title: "CNED",
          url: "etudes/en-france/cned",
          img: cned,
        },
        {
          title: "France Alumni",
          url: "etudes/en-france/france-alumni",
          img: alumni,
        },
        {
          title: "Bourses",
          url: "etudes/en-france/bourses",
          img: bourses,
        },
      ],
      "Algérie": [
        {
          title: "LIAD",
          url: "etudes/en-algerie/liad",
          img: liad,
        },
        {
          title: "PEH",
          url: "etudes/en-algerie/peh",
          img: peh,
        },
        {
          title: "Programmes universitaires",
          url: "etudes/en-algerie/essa",
          img: essa,
        },
      ]},
    // }[
    //   ({
    //     title: "Campus France",
    //     url: "etudes/en-france/campus-france",
    //     img: campus,
    //   },
    //   {
    //     title: "France Alumni",
    //     url: "etudes/en-france/france-alumni",
    //     img: alumni,
    //   },
    //   {
    //     title: "Bourses",
    //     url: "etudes/en-france/bourses",
    //     img: bourses,
    //   },
    //   {
    //     title: "LIAD",
    //     url: "etudes/en-algerie/liad",
    //     img: liad,
    //   },
    //   {
    //     title: "PEH",
    //     url: "etudes/en-algerie/peh",
    //     img: peh,
    //   },
    //   {
    //     title: "ESAA",
    //     url: "etudes/en-algerie/essa",
    //     img: essa,
    //   })
    // ],
    // submenu: [
    //   {
    //     title: "Campus France",
    //     url: "etudes/en-france/campus-france",
    //     img: campus,
    //   },
    //   {
    //     title: "France Alumni",
    //     url: "etudes/en-france/france-alumni",
    //     img: alumni,
    //   },
    //   {
    //     title: "Bourses",
    //     url: "etudes/en-france/bourses",
    //     img: bourses,
    //   },
    //   {
    //     title: "LIAD",
    //     url: "etudes/en-algerie/liad",
    //     img: liad,
    //   },
    //   {
    //     title: "PEH",
    //     url: "etudes/en-algerie/peh",
    //     img: peh,
    //   },
    //   {
    //     title: "ESAA",
    //     url: "etudes/en-algerie/essa",
    //     img: essa,
    //   },
    // ],
  },
  {
    title:"culture",
    //url:"culture",
    submenu:[
      {
        title: 'Nos actions culturelles',
        url:"culture/nos-actions-culturelles"
    },
        {
            title: 'Agenda',
            url:"culture/agenda",
            submenu: [
                {
                    title:"Musique",
                    url:"culture/agenda/musique"
                },
                {
                    title:"Théâtre",
                    url:"culture/agenda/theatre"
                },
                {
                    title:"Danse",
                    url:"culture/agenda/danse"
                },
                {
                    title:"Expo",
                    url:"culture/agenda/expo"
                },
                {
                  title:"Cinéma",
                  url:"culture/agenda/cinema"
                },
                {
                  title:"Conférences et rencontres",
                  url:"culture/agenda/conferences-et-rencontres"
                },
                {
                    title:"Jeunesse",
                    url:"culture/agenda/jeunesse"
                },
                {
                  title:"Ateliers",
                  url:"culture/agenda/ateliers"
                },
            ],
        },
        
    ]
},

{
  title:"Médiathèques",
  //url:"mediatheque",
  submenu:[
    {
      title:"Présentation",
      url:"mediatheque/presentation"
    },
    {
      title:"Inscription",
      url:"mediatheque/inscription"
    },
    
    {
      title:"Culturethèque",
      url:"mediatheque/culturetheque"
    },
    
  ],
  submenuNational:
    {
      title:"Médiathèques",
      url:"mediatheque"
    }
  
},
];
  export  {menuItems,mobileMenuItems}