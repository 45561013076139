import React,{useState,useEffect, useContext} from 'react'
import {Container} from 'react-bootstrap'
import styles from "./EtudierEnFrance.module.css"
import campus from "../../assets/img/Campus.png";
import Bandeau from 'components/Etudes/Bandeau'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import EtudesCarousel from 'components/Etudes/EtudesCarousel'
import EventsGrid from 'components/General/EventsGrid'
import BgShape from 'components/General/BgShape';
import InteractiveMapGrid from 'components/Etudes/InteractiveMapGrid';
// import LeafletDzMap from 'components/Etudes/LeafletDzMap';
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function Cned({region}) {
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='campus-france'))
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='cned')?.[0]?.data); 
setLoading(false)   
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);
  
//console.log(data)

try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? <ShimmerPostDetails card cta variant="SIMPLE" /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="red"  right="-60px" top="500px" rotate="-140deg"/>*/}
            {/*<BgShape type="yellow"  right="-50px" bottom="200px" rotate="-150deg"/>*/}
            {/*<BgShape type="blue"  left="-80px" bottom="400px" rotate="140deg"/>*/}
            <EtudesCarousel type="cned" img={srcImage}/>
            <Breadcrumb />
            <Container >
                <div>
                    <MainTitle className={`my-5 mb-4`}>{data?.Titre?.[language]??'CNED'}</MainTitle>
                    {text??
                    <p className='lh-lg text-black-50 text-start'>
                    L’accès à la scolarité en classe complète réglementée via le CNED en dehors du territoire français est réservé aux élèves pouvant justifier d’une inscription dans le système français en année N-1 (établissement français public ou privé, CNED réglementé ou établissement homologué à l’étranger) et disposant d’un avis d’orientation correspondant à la classe demandée.

Toute nouvelle inscription en classe complète réglementée (ou tout renouvellement d’inscription en maternelle, CP, 6ème ou seconde) est soumis à avis du service de coopération et d’action culturelle de l'ambassade de France en Algérie.

Dans tous les autres cas, il convient de procéder à une inscription en classe libre.

Toute demande de renseignement peut être adressée à cned@if-algerie.com   
                    </p>}
                </div>
               
            </Container>

           

            {/*<Container>
                <MainTitle className={`${styles.title} mb-5  mt-5`}>Agenda</MainTitle>
                <EventsGrid type="Campus France" region={region&&region}/>
  </Container>*/}
      </div>
    
  )
}

export default Cned